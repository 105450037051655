
// import React from 'react';
// // import Header from './../components/Header';
// import { useState } from 'react';
// import { Modal } from 'react-bootstrap';
// import { useHistory } from 'react-router-dom';
// import { Container, Col, Row } from 'reactstrap';
// import { getAmc } from './../stores/services/amc.service';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Link } from 'react-router-dom';
// import ReactTooltip from 'react-tooltip';
// import {
//   getFundByAmc,
//   UpdateNavOfAllFunds,
//   addDailyNav,
//   getDailyNavOfAllFunds
// } from './../stores/services/funds.service';
// import { AiFillPlusCircle } from 'react-icons/ai';
// import { BsTable } from 'react-icons/bs';
// import moment from 'moment';
// import { convertToObject } from 'typescript';

// const NavComponent = () => {
//   let date = new Date();

//   const email = sessionStorage.getItem('email') || '';
//   const amcCode = sessionStorage.getItem('amc_code') || '';
//   const [Loading, setLoading] = useState(false);
//   const [updateLoading, setUpdateLoading] = useState(false);
//   const [amcdata, setAmcdata] = useState<any>([]);
//   const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
//   const [NAVdate, setNAVDate] = useState(moment(date).format('YYYY-MM-DD'));

//   const [allDailyNav, setAllDailyNav] = useState<any>([]);


//   React.useEffect(() => { 
//     const fetchAmc = async () => {
//       amcdata.length = 0;
//       setAmcdata(amcdata);
//       //get all Amc for dropdown
//       try {
//         const amcResponse = await getAmc(email);
//         setAmcdata(amcResponse.data.data);
//       } catch (error) {}
//     };
//     fetchAmc(); 
//   }, []);

//   // it just remove fund_ from Symbol_code 
//   const extractKey = (symbolCode: any) => symbolCode.includes('FUND_') ? symbolCode.split('_')[1] :  symbolCode;
 
//   const getDailyNav = async () => {
//     // setLoading(true);
//     setAccFundLoading(true);
//     allDailyNav.length = 0;
//     setAllDailyNav(allDailyNav); 
//     try {
//       const response = await getDailyNavOfAllFunds(email, amcCode, NAVdate); 
//       let fooArray = Object.entries(response.data);
//       fooArray.forEach(([key, value]) => {
//         allDailyNav.push(value)
        
//       })
//       // setAllDailyNav(dailynav => ([...dailynav, ...allDailyNav]));
//       setAccFundLoading(false);
//       // setLoading(false);
//     } catch (error) {}
//       setAccFundLoading(false);
//       // setLoading(false);
//   };


//   const [code, setCode] = useState(''); 

//   //render dropdown for amc data
//   const renderAmcDropdown = () => {
//     return amcdata.map((item: any, index: number) => {
//       return (
//         <option key={index} value={item.amc_code}>
//           {item.name} ({item.amc_code})
//         </option>
//       );
//     });
//   };
 
//   // const isFundDataValid = (obj: any) => {
//   //   for (let i = 0; i < obj.length; i++) {
//   //     if(isZeroOREmpty(parseInt(obj[i].total_fund_units)) && !isZeroOREmpty(parseInt((obj[i].total_net_asset)))) {
//   //       document.getElementById(`txt-tna-${i}`).style.border = '1px solid red';
//   //       document.getElementById(`txt-tfu-${i}`).style.border = '1px solid red';
//   //       setUpdateLoading(false);
//   //       toast.error('Division by Zero detected');
//   //       return false;
//   //     }
//   //     else { 
//   //       document.getElementById(`txt-tna-${i}`).style.border = '';
//   //       document.getElementById(`txt-tfu-${i}`).style.border = '';
//   //     }
//   //   }
//   //   return true;
//   // }

  
//   const AddNavValue = async (obj: any) => {
//     setUpdateLoading(true);
//     try { 
//      const response = await addDailyNav(email, allDailyNav); 
//       toast.success('NAV updated');
//       setUpdateLoading(false); 
//     } catch (error) {
//       toast.error(error.response.data.message);
//       setUpdateLoading(false);
//     }
//   };

//   const decimalValue = (value, len) => {
//     if (value === 0 || value === parseInt(value, 10)) return value;
//     value = value.toString();
//     let startingValue = value.split('.')[0];
//     let decimalVal = value.split('.')[1];
//     let temp = '';
//     if (decimalVal !== undefined) {
//       let decimalString = decimalVal.toString();
//       if (decimalString.length <= len) {
//         temp = startingValue + '.' + decimalString;
//       } else {
//         temp = startingValue + '.' + decimalString.substr(0, len);
//       }
//     } else {
//       temp = startingValue;
//     }
//     return temp;
//   };

//   const renderData = () => {
//     return allDailyNav.map((items: any, index: any) => {
//       items.offer_rate= 'sale';
//       return (
//         <tr key={index}> 
//           <td>{items.fund}</td> 
//           <td>
//             <div className="form-group">
//               <input type="number" className="form-control"
//                 value={items.total_net_asset || 0}
//                 id={`txt-tna-${index}`}
//                 onChange={(e) => {	
//                   items.total_net_asset = e.target.value;
//                   items.nav = (items.total_net_asset/items.total_fund_units).toFixed(4) || 0;
//                 //  setAllDailyNav(dailynav => ([...dailynav, items]))
//                   setCode(e.target.value);

//                 }}
//                />
//             </div>
//           </td>
//           <td>
//             <div className="form-group">
//               <input type="number" className="form-control"
//                 value={items.total_fund_units || 0}		
//                 id={`txt-tfu-${index}`}
//                 onChange={(e) => {		
//                   items.total_fund_units = e.target.value;
//                   items.nav = (items.total_net_asset/items.total_fund_units).toFixed(4) || 0; 
//                   setCode(e.target.value);	 
//                 }}
               
//                />
//             </div>
//           </td>
//           <td>
//             <div className="form-group">
//               <input type="number" className="form-control"  readOnly
//                 value={items.nav || 0}
//                 onChange={(e) => {
//                   var a=decimalValue(e.target.value,4);
//                   items.nav = a;
//                   setCode(e.target.value);	 
//                 }}
//                 />
//             </div>
 
//           </td>
//           <td>
//           <input type="number" className="form-control" 
//                 value={items.offer_price || 0}
//                 onChange={(e) => {
//                   var a=decimalValue(e.target.value,4);
//                   items.offer_price = a || 0;
//                   setCode(e.target.value);	 
//                 }}
//                 />
//           </td>
//           <td>
//           <input type="number" className="form-control" 
//                 value={items.redemption_price || 0}
//                 onChange={(e) => {
//                   var a=decimalValue(e.target.value,4);
//                   items.redemption_price = a || 0;
//                   setCode(e.target.value);	 
//                 }}
//                 />
//           </td>
//         </tr>
//       );
//     });
//   };

//   return (
//     <>
// <div className="content">
//   <Row>
//     <Col md="12">
//     <Container fluid>
//         <ToastContainer limit={1} />
//         {/* <Header /> */}
//         <div className="card">
//           <div className="card-header">
//             <h4 className="card-title">Change NAV of Funds</h4>
//           </div>
//           {!Loading ? (
//             <div className="card-body">
//               <div className="row">

//                 <div className="col-md-3">
//                   <div className="form-group">
//                     <label>NAV Date:</label>
//                       <input type="date" className="form-control"
//                         value={NAVdate}		
//                         onChange={(e) => {		
//                           setNAVDate(e.target.value);	
                          	 
//                         }}	 />
//                   </div>
//                 </div>

//                 <div className="col-md-2">
//                   <label ></label>
//                   <button className="btn btn-primary btn-block" onClick={() => getDailyNav()}>Load</button>
//                 </div>

//               </div>
//               {allDailyNav.length > 0 ? (
//                 <table className="table" id="myTable">
//                   <thead>
//                     <tr>
//                       <th>Fund</th> 
//                       <th>Total Net Assets</th>
//                       <th>Total Fund Units Issued</th>
//                       <th>Daily NAV</th>
//                       <th>Offer Price</th>
//                       <th>Redemption Price</th>
//                     </tr>
//                   </thead>
//                   <tbody id="myUL">{renderData()}</tbody>
//                 </table>
//               ) : accFundLoading ? (
//                 ''
//               ) : (
//                 <div className="table-empty">
//                   <BsTable className="icon" />
//                     <p className="text-center empty-text">Empty</p>              
//                 </div>
//               )}

//               {allDailyNav.length > 0 && (
//                 <div className="">
//                   <button
//                     className="btn btn-primary"
//                     onClick={() => {
//                       AddNavValue(allDailyNav);  
//                     }}
//                     disabled={Boolean(updateLoading)}
//                   >
//                     {updateLoading ? (
//                       <>
//                         <span
//                           className="spinner-border login-txt spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>
//                         <span className="login-txt"> Loading...</span>
//                       </>
//                     ) : (
//                       <span>Update</span>
//                     )}
//                   </button>
//                 </div>
//               )}
//             </div>
//           ) : (
//             <div className="d-flex justify-content-center my-5">
//               <i className="fa fa-spinner fa-spin fa-3x" ></i> 
//             </div>
//           )}
          
//         </div>
//       </Container>
   
//     </Col>
//   </Row>
// </div>

   
//     </>
//   );
// };

// export default NavComponent;



import React from 'react';
// import Header from './../components/Header';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { getAmc } from './../stores/services/amc.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  getFundByAmc,
  UpdateNavOfAllFunds,
  addDailyNav,
  getDailyNavOfAllFunds
} from './../stores/services/funds.service';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsTable } from 'react-icons/bs';
import moment from 'moment';
import { convertToObject } from 'typescript';

const NavComponent = () => {
  let date = new Date();

  const email = sessionStorage.getItem('email') || '';
  const amcCode = sessionStorage.getItem('amc_code') || '';
  const [Loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [amcdata, setAmcdata] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [NAVdate, setNAVDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [allDailyNav, setAllDailyNav] = useState<any>([]);


  React.useEffect(() => { 
    const fetchAmc = async () => {
      amcdata.length = 0;
      setAmcdata(amcdata);
      //get all Amc for dropdown
      try {
        const amcResponse = await getAmc(email);
        setAmcdata(amcResponse.data.data);
      } catch (error) {}
    };
    fetchAmc(); 
  }, []);

  // it just remove fund_ from Symbol_code 
  const extractKey = (symbolCode: any) => symbolCode.includes('FUND_') ? symbolCode.split('_')[1] :  symbolCode;
 
  const getDailyNav = async () => {
    setLoading(true);
    setAccFundLoading(true);
    allDailyNav.length = 0;
    setAllDailyNav(allDailyNav); 
    try {
      const response = await getDailyNavOfAllFunds(email, amcCode, NAVdate); 
      let fooArray = Object.entries(response.data);
      fooArray.forEach(([key, value]) => {
        allDailyNav.push(value)
        
      })
      // setAllDailyNav(dailynav => ([...dailynav, ...allDailyNav]));
      setAccFundLoading(false);
      setLoading(false);
    } catch (error) {}
      setAccFundLoading(false);
      setLoading(false);
  };


  const [code, setCode] = useState(''); 

  //render dropdown for amc data
  const renderAmcDropdown = () => {
    return amcdata.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code}>
          {item.name} ({item.amc_code})
        </option>
      );
    });
  };
 
  // const isFundDataValid = (obj: any) => {
  //   for (let i = 0; i < obj.length; i++) {
  //     if(isZeroOREmpty(parseInt(obj[i].total_fund_units)) && !isZeroOREmpty(parseInt((obj[i].total_net_asset)))) {
  //       document.getElementById(`txt-tna-${i}`).style.border = '1px solid red';
  //       document.getElementById(`txt-tfu-${i}`).style.border = '1px solid red';
  //       setUpdateLoading(false);
  //       toast.error('Division by Zero detected');
  //       return false;
  //     }
  //     else { 
  //       document.getElementById(`txt-tna-${i}`).style.border = '';
  //       document.getElementById(`txt-tfu-${i}`).style.border = '';
  //     }
  //   }
  //   return true;
  // }

  
  const AddNavValue = async (obj: any) => {
    setUpdateLoading(true);
    try { 
     const response = await addDailyNav(email, allDailyNav); 
      toast.success('NAV updated');
      setUpdateLoading(false); 
    } catch (error) {
      toast.error(error.response.data.message);
      setUpdateLoading(false);
    }
  };

  const decimalValue = (value, len) => {
    if (value === 0 || value === parseInt(value, 10)) return value;
    value = value.toString();
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const renderData = () => {
    return allDailyNav.map((items: any, index: any) => {
      items.offer_rate= 'sale';
      return (
        <tr key={index}> 
          <td>{items.fund}</td> 
          <td>
            <div className="form-group">
              <input type="number" className="form-control"
                value={items.total_net_asset || 0}
                id={`txt-tna-${index}`}
                onChange={(e) => {	
                  items.total_net_asset = e.target.value;
                  items.nav = (items.total_net_asset/items.total_fund_units).toFixed(4) || 0;
                //  setAllDailyNav(dailynav => ([...dailynav, items]))
                  setCode(e.target.value);

                }}
               />
            </div>
          </td>
          <td>
            <div className="form-group">
              <input type="number" className="form-control"
                value={items.total_fund_units || 0}		
                id={`txt-tfu-${index}`}
                onChange={(e) => {		
                  items.total_fund_units = e.target.value;
                  items.nav = (items.total_net_asset/items.total_fund_units).toFixed(4) || 0; 
                  setCode(e.target.value);	 
                }}
               
               />
            </div>
          </td>
          <td>
            <div className="form-group">
              <input type="number" className="form-control"  readOnly
                value={items.nav || 0}
                onChange={(e) => {
                  var a=decimalValue(e.target.value,4);
                  items.nav = a;
                  setCode(e.target.value);	 
                }}
                />
            </div>
 
          </td>
          <td>
          <input type="number" className="form-control" 
                value={items.offer_price || 0}
                onChange={(e) => {
                  var a=decimalValue(e.target.value,4);
                  items.offer_price = a || 0;
                  setCode(e.target.value);	 
                }}
                />
          </td>
          <td>
          <input type="number" className="form-control" 
                value={items.redemption_price || 0}
                onChange={(e) => {
                  var a=decimalValue(e.target.value,4);
                  items.redemption_price = a || 0;
                  setCode(e.target.value);	 
                }}
                />
          </td>
        </tr>
      );
    });
  };

  return (
    <>
<div className="content">
  <Row>
    <Col md="12">
    <Container fluid>
        <ToastContainer limit={1} />
        {/* <Header /> */}
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Change NAV of Funds</h4>
          </div>
            <div className="card-body">
              <div className="row">

                <div className="col-md-3">
                  <div className="form-group">
                    <label>NAV Date:</label>
                      <input type="date" className="form-control"
                        value={NAVdate}		
                        onChange={(e) => {		
                          setNAVDate(e.target.value);	
                          	 
                        }}	 />
                  </div>
                </div>

                <div className="col-md-2">
                  <label ></label>
                  <button className="btn btn-primary btn-block"
                  disabled={Boolean(Loading)}
                  onClick={() => getDailyNav()}>Load</button>
                </div>

              </div>

              {Loading ? (
              <div className="d-flex justify-content-center my-5">
              <i className="fa fa-spinner fa-spin fa-3x" ></i> 
              </div>
              ):(
              allDailyNav.length > 0 ? (
                <>
                 <div className='table-responsive'>
                <table className="table" id="myTable">
                  <thead>
                    <tr>
                      <th>Fund</th> 
                      <th>Total Net Assets</th>
                      <th>Total Fund Units Issued</th>
                      <th>Daily NAV</th>
                      <th>Offer Price</th>
                      <th>Redemption Price</th>
                    </tr>
                  </thead>
                  <tbody id="myUL">{renderData()}</tbody>
                </table>
                </div>
                
              <div className="">
              <button
                className="btn btn-primary"
                onClick={() => {
                  AddNavValue(allDailyNav);  
                }}
                disabled={Boolean(updateLoading)}
              >
                {updateLoading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </button>
              </div>
              </>
              ) : (
                <div className="table-empty">
                  <BsTable className="icon" />
                    <p className="text-center empty-text">Empty</p>              
                </div>
              )


)}
            </div>
            
          
        </div>
      </Container>
   
    </Col>
  </Row>
</div>

   
    </>
  );
};

export default NavComponent;

