import React from 'react';

var accountTitle_AccountNumber_Regex = /^[0-9a-zA-Z -]+$/;
function numberWithCommas(amount:any){
  if(amount==='NaN' || amount===NaN || amount==='' || amount===undefined){
    return ''
 }else{
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
 }
}
function toProperCase(str) {
    return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
  }

export {numberWithCommas, toProperCase,accountTitle_AccountNumber_Regex};