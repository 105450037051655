
import React, { useState } from 'react';
// import Header from './../components/Header';	
import { Modal } from 'react-bootstrap';	
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';	
import { useHistory } from 'react-router-dom';	
import moment from 'moment';	
import { getAllPendingTransactions } from './../stores/services/transactions.service';	
import ReactTooltip from 'react-tooltip';	
import ViewTransaction from './view-beta-txn';	
import { transaction } from 'mobx';
import { BsTable } from 'react-icons/bs';	

const RejectedTransactions = () => {	
  const history = useHistory();	
  let [transactions, setTransactions] = useState([]);	
  let [searchValue, setSearchValue] = useState('');	
  const [trx_history, setTrxHistory] = useState([]);	
  const [viewChecklist, setViewCheckList] = useState(false);	
  const [trx_checklist, setTrxCheckList] = useState([]);	
  let [activePage, setActivePage] = useState('');	
  let [paginationArr, setPaginationArr] = useState(['']);	
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  
  const [resLoading, setResLoading] = useState(false);

  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  React.useEffect(() => {	
    async function getTransactions(){
      setResLoading(true);
      await getAllPendingTransactions(	
      sessionStorage.getItem('email') || '',	
      'REJECTED-AMC',	
      searchValue,	
      '',	
      activePage	
    )	
      .then((response) => {	
        let pageArr = paginationArr;	
        let existingPageIndex = paginationArr.findIndex(	
          (x) => x === response.data.page_info.next_page_number	
        );	
        if (	
          existingPageIndex === -1 &&	
          response.data.data.length === 10 &&	
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
        ) {	
          pageArr.push(response.data.page_info.next_page_number);	
        }	
        setPaginationArr(pageArr);	
        setTransactions(response.data.data);
        setResLoading(false);	
      })	
      .catch((err) => {	
        setResLoading(false);
      });	
      setResLoading(false);
    }
    getTransactions();
  }, [activePage]);	

  const CapTxnType = (type: any) => {	
    let i, txType;	
    for (i = 0; i < txnTypes.length; i++) {	
      if (txnTypes[i].api_name === type) {	
        txType = txnTypes[i].dis_name;	
        break;	
      }	
    }	
    return txType;	
  };	
  const searchFilters = async(searchVar = '') => {	
    return new Promise(async(resolve, reject) => {
      let search=false;
      let clear=false;
      if(searchVar===''){ // if click on clear button
        search=false;
        clear=true;
      }else{ // if click on search button
        search=true;
        clear=false;
      }
      setResLoading(true);	
      setSearchLoading(search);
      setClearLoading(clear);
      await getAllPendingTransactions(	
        sessionStorage.getItem('email') || '',	
        'REJECTED-AMC',	
        searchVar,	
        '',	
        activePage	
      )	
        .then((response) => {	
          let pageArr = [''];	
          let existingPageIndex = paginationArr.findIndex(	
            (x) => x === response.data.page_info.next_page_number	
          );	
          if (	
            existingPageIndex === -1 &&	
            response.data.data.length === 10 &&	
            10 * pageArr.length !==	
              parseInt(response.data.page_info.total_count)	
          ) {	
            pageArr.push(response.data.page_info.next_page_number);	
          }	
          setPaginationArr(pageArr);	
          setTransactions(response.data.data);
          setResLoading(false);	
          setSearchLoading(false);
          setClearLoading(false);
          resolve('Success');	
        })	
        .catch((err) => {	
          setResLoading(false);
          setSearchLoading(false);	
          setClearLoading(false);	
          reject('Error');	
        });
        setResLoading(false);
        setSearchLoading(false);	
        setClearLoading(false);		
    });	
  };	
  const [note, setNote] = useState('');

  const renderNote = (txn_history) => {	
    return txn_history.map((txn) => {	
      if (txn.action === 'REJECTED') {	
        return <p style={{ fontSize: '16px' }}>{txn.note}</p>;	
      }	
    });	
  };	
  const renderRejectedBy = (txn_history) => {	
    let temp = txn_history.filter((txn) => txn.action === 'REJECTED-AMC');	
    if (temp.length > 1) {	
      return (	
        <p style={{ fontSize: '16px' }}>{temp[temp.length - 1].updated_by}</p>	
      );	
    } else if (temp.length == 1) {	
      return <p style={{ fontSize: '16px' }}>{temp[0].updated_by}</p>;	
    } else return;	
  };	
  const [view, setView] = useState(false);	
  const [data, setData] = useState<any>([]);	
  const titleCase = (value: string) => {	
    let sentence = value.toLowerCase().split('_');	
    for (let i = 0; i < sentence.length; i++) {	
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);	
    }	
    return sentence.join(' ');	
  };	
  const renderData = () => {	
    return data.map((value: any, index: number) => {	
      return (	
        <>	
          {value[0] !== 'txn_history' && value[0] !== 'file' ? (	
            <Col md="6" key={index}>	
              <div className="input-holder left">	
                <p className="label">{titleCase(value[0])}</p>	
                <div className="input-1 hide-overflow">	
                  <p	
                    style={{	
                      fontSize: '12px',	
                    }}	
                  >	
                    {typeof value[1] === 'string'	
                      ? value[0] === 'file'	
                        ? 'N/A'	
                        : value[1]	
                      : value[0] === 'file'	
                      ? 'N/A'	
                      : ''}	
                  </p>	
                </div>	
              </div>	
            </Col>	
          ) : (	
            ''	
          )}	
        </>	
      );	
    });	
  };	
  const viewCheckListPopup = () => {	
    switch (viewChecklist) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
          >	
            <div className="modal-header  ">	
                        <button	
                          aria-hidden={true}	
                          className="close"	
                          data-dismiss="modal"	
                          type="button"	
                          onClick={() => {	
                            setViewCheckList(false);	
                          }}	
                        >	
                          <i className="tim-icons icon-simple-remove" />	
                        </button>	
                        <h6 className="title title-up"> APPROVAL CHECK LIST  </h6>	
                      </div>	
              <div className="modal-body">	
              <div> 
              <div className={'checlist-scroll'}>	
                {trx_checklist.map((val: any, ind: any) => {	
                  return (	
                    <Row key={ind} className="pt-3">	
                      	
                      <Col md="4">	
                      <text className="text-primary">{val.serial_no}.</text> <text>{val.message}</text>	
                      </Col>	
                      <Col md="8">	
                        <div className="row"> 	
                            <div className="col-sm-3">	
                             	
                            <FormGroup check>	
                              <Label check>	
                                <Input 	
                                checked={val.value == 'compliant'}	
                                type="checkbox"	
                                value="compliant"	
                                readOnly />	
                                <span className="form-check-sign" />	
                                Compliant	
                              </Label>	
                            </FormGroup>	
                            </div>	
                            <div className="col-sm-3">	
 	
                            <FormGroup check>	
                              <Label check>	
                                <Input type="checkbox"	
                                id="unlisted"	
                                checked={val.value == 'na'}	
                                value="na"	
                                readOnly />	
                                <span className="form-check-sign" />	
                                N /A	
                              </Label>	
                            </FormGroup>	
                            </div>	
                            <div className="col-sm-6">	
                              <div className="form-group">	
                                <input	
                                  type="text"	
                                  placeholder="Comments"	
                                  value={val.comment}	
                                  className="form-control"	
                                  readOnly	
                                />	
                                </div> 	
                            </div>	
                        </div>	
                      </Col>	
                    </Row>	
                  );	
                })}	
            	</div>

              {note !== '' && (
                  <Row>
                    <div className="col-md-12">
                    <label>Reason of Reject</label>
                    <div className="form-group">
                      <textarea
                        placeholder="Reason"
                        className={
                          'form-control w-100'}
                        value={note}
                        readOnly
                      ></textarea>
                    </div>
                    </div>
                  </Row>
                )}

                <Row>	
                  <Col>	
                    <div className=" ">	
                      <button	
                        className="btn btn-primary mt-3"	
                        onClick={() => {	
                          setViewCheckList(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };
  
  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        {/* <Header />	 */}
        {!view ? (	
          <div className="card ">	
            <div className="card-header">	
              <h4 className="card-title">Rejected Transactions</h4>	
            </div>	
            <div className="card-body">	
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                  <ReactTooltip	
                      textColor="white"	
                      backgroundColor="black"	
                      effect="float"	
                    />	
                      <input	
                        type="search"	
                        id="myInput"	
                        placeholder="Transaction Id"	
                        data-tip="Search Transaction"	
                        className="form-control"	
                        value={searchValue}	
                        onChange={(e) => {	
                          setSearchValue(e.target.value);	
                        }}	
                        	
                      /> 	
                  </div>
                </div>
                <div className="col-md-4">
                <button	
                    className="btn btn-primary ml-2 mt-a"
                    disabled={Boolean(searchLoading)}	
                    onClick={() => {	
                      if (searchValue.trim() !== '') {	
                        searchFilters(searchValue).then(() => {	
                          setActivePage('');	
                        });	
                      }	
                    }}	
                  >	
                  {searchLoading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Searching...</span>
                    </>
                    ) : (
                    'Search'
                    )}
                     {/* <span>	
                      <i className="fa fa-search mr-2"></i>Search	
                    </span>	 */}
                  </button>	

                  <button	
                    className="btn btn-default ml-2 mt-a"	
                    disabled={Boolean(clearLoading)}
                    // style={{	
                    //   width: '148px',	
                    // }}	
                    onClick={() => {	
                      // paginationArr.length = 0;	
                      // setPaginationArr(paginationArr);	
                      // searchFilters('').then(() => {	
                      //   setActivePage('');	
                      //   setSearchValue('');	
                      // });	
                      searchFilters('').then(() => {	
                        setSearchValue('');	
                        setActivePage('');	
                      });
                    }}	
                  >	
                  {clearLoading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Clear</span>
                    </>
                    ) : (
                    'Clear'
                    )}
                     {/* <span>	
                      <i className="fa fa-trash mr-2"></i>Clear	
                    </span>	 */}
                  </button>	

                </div>
              </div> 

              <div>
                {resLoading ? (
                  <div className="d-flex justify-content-center my-5">	
                  <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
                  </div>
                ):(
                  transactions.length>0 ? (
                    <div className='table-responsive'>
                  <table className="table mt-1">	
                    <thead>	
                      <tr>	
                        <th>Transaction Id</th>	
                        <th>Transaction Type</th>		
                        <th>Date Created</th>	
                        <th>Status</th>	
                        <th>Net Amount</th>
                        {/* <th>Reason</th> */}	
                        <th>Rejected By</th>	
                        <th>Actions</th>	
                      </tr>	
                    </thead>	
                    <tbody>	
                      {transactions.map((transaction: any) => {	
                        return (	
                          <tr>	
                            <td>{transaction.txn_id}</td>	
                            <td>	
                              {transaction.txnr_type	
                                ? CapTxnType(transaction.txnr_type)	
                                : 'N/A'}	
                            </td>	
                            <td>	
                              {transaction.txn_history	
                                ? transaction.txn_history[0]?.created_at	
                                  ? moment(	
                                      transaction.txn_history[0].created_at	
                                    ).format('DD-MM-YYYY')	
                                  : 'N/A'	
                                : 'N/A'}	
                            </td>	
                            <td>{transaction.txn_status}</td>	
                            <td>{numberWithCommas(transaction.net_amount)}</td>
                            {/* <td>{renderNote(transaction.txn_history)}</td> */}	
                            <td>{renderRejectedBy(transaction.txn_history)}</td>	
                            <td className=" ">	
                              <div className="multi-icons pointer">	
                                
                                <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {	
                                    setView(true);	
                                    setData(transaction);	
                                    setTrxHistory(transaction.txn_history);
                                  }}	>
                                      <i className="fa fa-eye">
                                  </i>
                                </button>  	

                                <button type="button" className="btn-icon btn-link like btn btn-success btn-sm" onClick={() => {	
                                    sessionStorage.setItem(	
                                      'rejectedTxObj',	
                                      JSON.stringify(transaction)	
                                    );	
                                    sessionStorage.setItem('rejectedFlag', 'true');	
                                    history.replace(	
                                      `/admin/beta-transaction/${transaction.txnr_type}`	
                                    );	
                                  }}>
                                      <i className="tim-icons icon-pencil">
                                  </i>
                                </button> 

                              </div>	
                            </td>	
                          </tr>	
                        );	
                      })}	
                    </tbody>	
                  </table>
                  </div>
                ):(
                  <div className="table-empty">	
                  <BsTable className="icon" />	
                  <p className="text-center empty-text">No record found</p>	
                  </div>
                )
                )}	
              <div	
                className="row pointer pr-3"	
                style={{	
                  float: 'right',	
                }}	
              >	
                {/* PREVIOUS BUTTON */}	
                {activePage !== paginationArr[0] ? (	
                  <i	
                    className="fa fa-arrow-left "	
                    aria-hidden="true"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(paginationArr[index - 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                  ''	
                )}	
                {/* NEXT BUTTON */}	
                {activePage !== paginationArr[paginationArr.length - 1] ? (	
                  <i	
                    className="fa ml-3 fa-arrow-right"	
                    aria-hidden="true"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(paginationArr[index + 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                  ''	
                )}	
              </div>
              </div>	
            </div>	
          </div>	
        ) : (	
          <div className="">	
            <h4 className="card-title">	
              <i className="fa fa-angle-left mr-2 pointer"	
                onClick={() => {	
                  setView(false);	
                }}></i>	
              View - Rejected Transaction	
            </h4>	
            <ViewTransaction data={data} />	
            <div className="line"></div>	
            <Row>	
            {trx_history.map((hist: any, index: number) => {	
                return (	
                  <Col
                    md="3"
                    onClick={() => {
                      if (hist.action === 'REJECTED-AMC') {
                        setNote(hist.note);
                      } else {
                        setNote('');
                      }
                      if (hist.action === 'CREATED') {
                        return;
                      }
                      if (!hist.check_list) return;
                      setTrxCheckList(hist.check_list);
                      setViewCheckList(true);
                    }}
                  >
                    <div className="card process-card pointer">
                      <div className="card-header">
                        <h4 className="card-title">
                        <i className="tim-icons icon-single-02 mr-1"></i> <span className="card-number">
                          {index+1}
                          </span> {hist.updated_by_role}
                        </h4>
                      </div>	 
                      <div className="card-body text-center">	
                      <p className="small mt-2">Status:</p>	
                      <h3 className="accepted text-primary">{hist.action}</h3>	
                      {hist.updated_at !== '' ? (	
                        <>	
                          <p className="small mt-2">by</p>	
                          <h4 className="comment text-primary">	
                            {hist.updated_by}	
                          </h4>	
                          <p className="small mt-2">at</p>
                          <h4 className="comment text-primary">	
                            {moment(hist.updated_at).format('DD-MM-YYYY, h:mm a')}	
                          </h4>	
                        </>	
                      ) : (	
                        ''	
                      )}	
                      {hist.note !== '' ? (	
                        <>	
                          <p className="small mt-2">Comments</p>	
                          <small className="comment dot-text" title={hist.note}>{hist.note}</small>	
                        </>	
                      ) : (	
                        ''	
                      )}	
                      {hist.created_by !== '' ? (	
                        <>	
                          <p className="small mt-2">Created By</p>	
                          <h4 className="text-primary">{hist.created_by}</h4>	
                          <p className="small mt-2">Created At</p>	
                          <h4 className="comment text-primary">	
                            {moment(hist.created_at).format('DD-MM-YYYY, h:mm a')}	
                          </h4>	
                        </>	
                      ) : (	
                        ''	
                      )}	
                      </div>
                    </div>	
                  </Col>	
                );	
              })}
            </Row>	
            {viewCheckListPopup()}
          </div>	
        )}	
      </Container>
      </Col>	
      </Row>
      </div>
    </>	
  );	
};	
export default RejectedTransactions;