import React, { useState } from 'react';	
// import Header from './../components/Header';	
import { Container, Row, Col, FormGroup, Input, Label } from 'reactstrap';	
import { ToastContainer, toast } from 'react-toastify';	
import { Modal } from 'react-bootstrap';	
import 'react-toastify/dist/ReactToastify.css';	
import { updateTransactionStatus } from './../stores/services/transactions.service';	
import { Link } from 'react-router-dom';	
import moment from 'moment';	
import { useHistory } from 'react-router-dom';	
import { getChecklist } from '../stores/services/checklist.service';	
import CheckListItem from '../components/checklist-item';	
import ViewTransaction from './view-beta-txn';	
import { head } from 'lodash';
const SignatoryA = (props: any) => {
  const[found, setFound]= useState(true);	
  const history = useHistory();	
  const [values, setValues] = useState<any>([]);	
  const [headLabel, setHeadLabel] = useState('');	
  const [checklistData, setCheckListData] = useState<any>([]);	
  const [status, setStatus] = useState('');	
  const [trx_id, setTrxId] = useState('');	
  const [trx_status, setTrxStatus] = useState('');	
  const [trx_history, setTrxHistory] = useState([]);	
  const [Loading, setLoading] = useState(false);	
  const [checklistSetPopup, setCheckListSetPopup] = useState(false);	
  const [viewChecklist, setViewCheckList] = useState(false);	
  const [trx_checklist, setTrxCheckList] = useState([]);	
  const [data, setData] = useState<any>([]);	
  const role_name = sessionStorage.getItem('role') || '';	
  React.useEffect(() => {	
    const getAllChecklists = async () => {	
      try {	
        const response = await getChecklist(email, role_name);	
        setCheckListData(response.data.data);	
      } catch (error) {	
        toast.error(error.response.data.message[0]);	
      }	
    };	
    getAllChecklists();	
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  }, []);	
  React.useEffect(() => {	
    setHeadLabel(props.match.params.type);	
    const obj = JSON.parse(sessionStorage.getItem('csab-txn') || '');	
    setData(obj);	
    setTrxId(obj.txn_id);	
    setTrxHistory(obj.txn_history);	
    setTrxStatus(obj.txn_status);	
    let objArr = Object.entries(obj);	
    setValues(objArr);	
  }, [props.match.params.type]);	
  const titleCase = (value: string) => {	
    let sentence = value.toLowerCase().split('_');	
    for (let i = 0; i < sentence.length; i++) {	
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);	
    }	
    return sentence.join(' ');	
  };	
  const email = sessionStorage.getItem('email') || '';	
  const acceptRejectTransaction = async (status: string) => {	
    setReasonText('');	
    setLoading(true);	
    try {	
      const response = await updateTransactionStatus(	
        email,	
        status,	
        trx_id,	
        reasonText,	
        checklistData	
      );	
      setLoading(false);	
      toast.success(response.data.message);	
      setTimeout(() => {
        // history.replace(`/admin/comp-sign-transactions/${props.match.params.type === 'CREATED'? role_name : props.match.params.type}`);
        history.replace(`/admin/comp-sign-transactions/${role_name.toLowerCase()}`);
      }, 1000);
    } catch (error) {		
      setLoading(false);	
      toast.error(error.response.data.message);	
    }	
  };	
  const [reasonText, setReasonText] = useState('');	
  const [reasonTextError, setReasonTextError] = useState('');	
  const [statusError, setStatusError] = useState('');	
  const updateTxStatus = async () => {
    const obj = JSON.parse(sessionStorage.getItem('csab-txn') || '');

    const isValid = validate();
    if (isValid) {
      if (status === 'accept') {
        setStatusError('');
        if (
          headLabel.toUpperCase() === 'AUTHORIZER-A' ||
          headLabel.toUpperCase() === 'CREATED' ||
          headLabel.toUpperCase() === 'AUTHORIZER-B'
        ) {
          if (trx_status === 'CREATED') {
            if (role_name === 'AUTHORIZER-A') {
              acceptRejectTransaction('AUTHORIZER-B');
            } else {
              acceptRejectTransaction('AUTHORIZER-A');
            }
          } else if (
            trx_status === 'AUTHORIZER-A' ||
            trx_status === 'AUTHORIZER-B'
          ) {
            acceptRejectTransaction('COMPLIANCE');
          } else {
            toast.error(
              'You cannot Accept Transaction as Compliance hasnt verified yet'
            );
          }
        }
      } else {
        setStatusError('');
        if (reasonText.trim() === '') {
          setReasonTextError('Required');
        } else {
          setReasonTextError('');
          acceptRejectTransaction('REJECTED-AMC');
        }
      }
    }
  };
  const validate = () => {	
    let statusErr = '';	
    status.trim() === '' ? (statusErr = 'Required') : (statusErr = '');	
    if (statusErr) {	
      setStatusError(statusErr);	
      return false;	
    } else {	
      return true;	
    }	
  };	
  const updateFormData = (obj, id) => {	
    let temp = checklistData;	
    temp[id] = obj;	
    setCheckListData(temp);	
  };	
  const viewCheckListPopup = () => {	
    switch (viewChecklist) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="xl"	
          >	
            <div className="modal-header ">
               <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {	
                  setViewCheckList(false);	
                  }}>
                          <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h6 className="title title-up">Approval Check List </h6>
                      </div>

            <div className="modal-body">	
              <div className=" ">	 
              <div className={'checlist-scroll'}>
                {trx_checklist.map((val: any, ind: any) => {	
                  return (	
                    <Row key={ind} className="pt-3">	
                      	
                      <Col md="5">	
                      <text className="text-primary">{val.serial_no}</text> <text>{val.message}</text>	
                      </Col>	
                      <Col md="7">	
                        <Row>	
                          <Col md="3">	
                         	
                              <FormGroup check>	
                                <Label check>	
                                  <Input 	
                                  checked={val.value == 'compliant'}	
                                  type="checkbox"	
                                  value="compliant"	
                                  readOnly />	
                                  <span className="form-check-sign" />	
                                  Compliant	
                                </Label>	
                              </FormGroup>	
                              </Col>	
                              <Col md="2"> 	
                           <FormGroup check>	
                            <Label check>	
                              <Input type="checkbox" 	
                              checked={val.value == 'na'}	
                              value="na"	
                              readOnly />	
                              <span className="form-check-sign" />	
                              N /A	
                            </Label>	
                          </FormGroup>	
                              </Col>	
                              <Col md="7">	
                              <FormGroup>	
                                <input	
                                  type="text"	
                                  placeholder="Comments"	
                                  value={val.comment}	
                                  className="form-control"	
                                  readOnly />	
                                </FormGroup>	
                            	
                          </Col>	
                        </Row>	 
                      </Col>	
                    </Row>	
                  );	
                })}	
                </div>
                <Row>	
                  <Col>	
                    <div className=" ">	
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setViewCheckList(false);	
                        }} >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const renderCheckListPopup = () => {	
    switch (checklistSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="xl">	
                <div className="modal-header ">	
                        <button	
                          aria-hidden={true}	
                          className="close"	
                          data-dismiss="modal"	
                          type="button"	
                          onClick={() => {	
                            setCheckListSetPopup(false);
    
                          }}	
                        >	
                          <i className="tim-icons icon-simple-remove" />	
                        </button>	
                        <h6 className="title title-up">Approval Check List  </h6>	
                      </div>	
            <div className="modal-body">	
              <div className=" ">	
                <div className=" "> 	
                <div className={'checlist-scroll'}>
                {checklistData.map((val: any, ind: any) => {	
                  return (	
                    <CheckListItem	
                      updateFormData={updateFormData}	
                      val={val}	
                      ind={ind}	
                    />	
                  );	
                })}	
                </div>
                <form action="">	
                <div className="mt-2 row">	
                  <div className="col-sm-2"> 	
                      <FormGroup check>	
                        <Label check>	
                          <Input type="checkbox" 	
                            value="accept"	
                            checked={status === 'accept'}	
                            id="accept"	
                            onChange={(e) => {	
                              setStatusError('');	
                              setStatus(e.target.value);	
                            }}	
                             	
                             />	
                          <span className="form-check-sign" />	
                          Accept	
                        </Label>	
                      </FormGroup>	
                    </div>	
                    <div className="col-sm-2"> 	
                    <FormGroup check>	
                        <Label check>	
                          <Input type="checkbox"	
                          checked={status === 'reject'}	
                          value="reject"	
                          id="reject"	
                          onChange={(e) => {	
                            setStatusError('');	
                            setStatus(e.target.value);	
                          }}	
                           />	
                          <span className="form-check-sign" />	
                          Reject	
                        </Label>	
                      </FormGroup>	
                    </div>	
                </div>	
                </form>	
                <Row>	
                  {statusError ? (	
                    <text className="error-labels">{statusError}</text>	
                  ) : (	
                    ''	
                  )}	
                </Row>	
                {status === 'reject' && (	
                  <Row>	
                    <div className="col-md-12">	
                      <div className="form-group mt-1">	
                      <textarea	
                        placeholder="Reason"	
                        className={	
                          'form-control w-100' +	
                          (reasonTextError ? 'err-border' : '')	
                        }	
                        value={reasonText}	
                        onChange={(e) => {	
                          setReasonText(e.target.value);	
                          setReasonTextError('');	
                        }}	
                      ></textarea>	
                      </div>	
                    </div>	
                  </Row>	
                )}	
                <Row className="">	
                  <Col> 	
                    <div className="mt-3">	
                      <button	
                        className="btn btn-primary"	
                        disabled={Boolean(Loading)}	
                        onClick={() => {
                          updateTxStatus();	
                        }}	
                      >	
                        {Loading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                          'Submit'	
                        )}	
                      </button>	
                      <button	
                        className="btn btn-default ml-3"	
                        onClick={() => {	
                          setCheckListSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                  	
                </Row>	
              </div>	
            </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  return (	
    <>	
      <div className="content">
  <Row>
    <Col md="12">
      <Container fluid>	
    
        {/* <Header />	 */}
        <ToastContainer limit={1} />	
          <h4 className="card-title">	
            {/* <Link to="/comp-sign-transactions/compliance"> 	
              <i className="fa fa-angle-left mr-2 fa-1x"></i>	
            </Link>	 */}
             <i className="fa fa-angle-left mr-2 mt-1 pointer" onClick={() => {
                      history.replace('/admin/'+headLabel.toLowerCase())
                  }}></i>
           Accept/Reject Transaction- {headLabel.toUpperCase() === 'CREATED'
                  ? role_name
                  : headLabel.toUpperCase()} </h4>	
            <ViewTransaction data={data} flag={true}/>
            {/* <div className="" id="mydiv"> */}
            {role_name !== 'TXN_CREATOR' && role_name !== 'TXN-CREATOR' && (	
            <Row>	
              <Col md="6">	
                  <button	
                    className="btn btn-primary mr-4 bg-positive"	
                    onClick={() => {	
                      setStatus('')
                      setCheckListSetPopup(true);	
                    }}	
                  >	
                    Action	
                  </button>	
              </Col>	
            </Row>	
            )}
{/* </div> */}

 {/* hide action button if already action by authorizer */}
         {/* {trx_history.slice(1, trx_history.length).map((hist: any, index: number) => {
          if(hist.updated_by === email) {

            var hidebtn=document.getElementById("mydiv");
            hidebtn.classList.add("hidden")
                }
                  
            })} */}
            
            <div className="line"></div>	
            <Row>
              {trx_history.map((hist: any, index: number) => {
                return (
                  <Col
                    md="3"
                    onClick={() => {
                      if (hist.action === 'CREATED') {
                        return;
                      }
                      if (!hist.check_list) {
                        toast.warning('Checklist data not found');
                        return;
                      }
                      setTrxCheckList(hist.check_list);
                      setViewCheckList(true);
                    }}
                  >
                     <div className="card process-card pointer ml-2 ">	
                     <div className="card-header">	 
                          <h4 className="card-title">	
                          <span className="card-number">
                          {index + 1}
                          </span>
                           <span className="mr-1">
                                </span> <i className="tim-icons icon-single-02 mr-1"></i> {hist.updated_by_role}
                          </h4>	
                       </div>	 
                        <div className="card-body text-center"> 
                          <p className="small mt-4">Status:</p>
                          <h3 className="accepted text-primary"> {hist.action} </h3>	 
                          {hist.updated_at !== '' ? (
                            <>
                             <p className="small mt-2">by</p>
                              <h4 className="comment text-primary">{hist.updated_by}</h4>	
                              <p className="small mt-2">at</p>
                              <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MMM-YYYY, h:mm a")}</h4>	 
                            </>
                          ) : (
                            ''
                          )}
                          {hist.note !== '' ? (
                            <>
                              <p className="small mt-2">Comments</p>
                              <small className="comment dot-text" title={hist.note}>{hist.note}</small>
                            </>
                          ) : (
                            ''
                          )}
                          {	
                            hist.created_by !== '' ? (
                              <>	
                                <p className="small mt-2">by</p>	
                                <h4 className="text-primary">{hist.created_by}</h4>	
                                <p className="small mt-2"> at</p>	
                                <h4 className="comment text-primary"> {moment(hist.created_at).format("DD-MMM-YYYY, h:mm a")}</h4>	
                              </>	
                            ) : (	
                                ''	
                              )}
                        </div>
                    </div>
                  </Col>
                )
              })}
            </Row>	
            {/* {viewCheckListPopup()}	 */}
            {renderCheckListPopup()}	
       	
      </Container>	

    </Col>
    </Row>
      </div>
    </>	
  );	
};	
export default SignatoryA;